<template>
  <div id="recaptcha"></div>
</template>

<script>
export default {
  name: 'ReCaptcha',

  props: {
    callback: {
      type: Function,
      required: true,
    },
  },

  mounted() {
    window.grecaptcha.ready(() => {
      window.grecaptcha.render('recaptcha', {
        sitekey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
        size: 'invisible',
        callback: this.handleToken,
      })
    })
  },

  methods: {
    execute() {
      window.grecaptcha.execute()
    },
    handleToken(token) {
      this.callback(token)

      window.grecaptcha.reset()
    },
  },
}
</script>
