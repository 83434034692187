<template>
  <div>
    <div
      class="tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-gray-200 tw-p-4 ie_tw-h-screen"
    >
      <div class="tw-w-full w-360">
        <form
          class="tw-bg-white tw-shadow-md tw-rounded tw-px-8 tw-pt-6 tw-pb-8 tw-mb-4"
          @submit.prevent="submit"
        >
          <div>
            <a :href="marketingSiteUrl" title="Click to visit">
              <HeaderLogo />
            </a>
          </div>
          <p
            class="tw-font-semibold tw-mb-4 tw-mt-8 tw-text-2xl tw-text-gray-800"
          >
            Sign Up
          </p>
          <div class="tw-mb-4">
            <label class="form-label" for="full_name">Full Name</label>
            <input
              id="full_name"
              ref="fullName"
              v-model="form.full_name"
              v-validate.disable="'required'"
              :readonly="inviting"
              data-vv-as="full name"
              name="full_name"
              class="form-control"
              data-cy="full-name"
              type="text"
              placeholder
              autocomplete="off"
              tabindex="1"
            />
            <p
              v-show="errors.has('full_name')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('full_name') }}
            </p>
          </div>
          <div class="tw-mb-4">
            <label class="form-label" for="email">Email</label>
            <input
              id="email"
              v-model="form.email"
              v-validate.disable="'required|email'"
              :readonly="inviting"
              name="email"
              data-cy="email"
              class="form-control"
              type="text"
              placeholder="john@example.com"
              autocomplete="off"
              inputmode="email"
              tabindex="2"
            />
            <p
              v-show="errors.has('email')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('email') }}
            </p>
          </div>
          <div class="tw-mb-5" data-cy="label-password">
            <label class="form-label" for="password">
              {{ inviting ? 'Set your password' : 'Password' }}
            </label>
            <PasswordStrengthMeter
              id="password"
              ref="password"
              v-model="form.password"
              v-validate.disable="'required|min:8|max:128'"
              name="password"
              data-cy="password"
              type="password"
              placeholder="Password"
              autocomplete="new-password"
              tabindex="3"
            />
            <p
              v-show="errors.has('password')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('password') }}
            </p>
          </div>
          <div class="tw-mb-5">
            <label class="form-label" for="confirm_password">
              Confirm Password
            </label>
            <input
              id="confirm_password"
              v-model="form.password_confirmation"
              v-validate.disable="'required|confirmed:password'"
              name="confirm_password"
              data-vv-as="confirm password"
              class="form-control"
              type="password"
              placeholder="Confirm Password"
              autocomplete="new-password"
              data-cy="confirm-password"
              tabindex="4"
            />
            <p
              v-show="errors.has('confirm_password')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('confirm_password') }}
            </p>
          </div>
          <ReCaptcha
            ref="recaptcha"
            :callback="isRegistrationSuccess ? loginRegisteredUser : register"
          ></ReCaptcha>
          <div class="tw-flex tw-items-center tw-justify-between">
            <SpinnerButton
              :disabled="loading"
              :loading="loading"
              :spinner-only="true"
              data-cy="btn-submit"
              type="submit"
              tabindex="5"
            >
              {{ inviting ? 'Join Team' : 'Create Company' }}
            </SpinnerButton>

            <RouterLink
              v-if="!inviting"
              :to="{ name: 'signin' }"
              class="tw-mx-0 btn btn-borderless btn-link btn-link_focus tw-text-blue-500"
              tabindex="6"
            >
              Sign In
            </RouterLink>
          </div>
        </form>

        <AuthFooter />
      </div>
    </div>
    <a
      href="https://bwr2j9ibei.execute-api.us-east-1.amazonaws.com/ProdStage/"
      rel="nofollow"
      style="display: none"
      aria-hidden="true"
      >Link</a
    >
  </div>
</template>

<script>
import AuthFooter from '@/components/AuthFooter'
import SpinnerButton from '@/components/SpinnerButton'
import ValidatesForm from '@/mixins/ValidatesForm'
import MarketingSiteUrl from '@/mixins/MarketingSiteUrl'
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter'
import HeaderLogo from '@/components/HeaderLogo'
import ReCaptcha from '@/components/ReCaptcha'

export default {
  name: 'SignUp',

  layout: 'DefaultLayout',

  components: {
    ReCaptcha,
    HeaderLogo,
    AuthFooter,
    SpinnerButton,
    PasswordStrengthMeter,
  },

  mixins: [ValidatesForm, MarketingSiteUrl],

  data: () => ({
    form: {
      full_name: '',
      email: '',
      password: '',
      password_confirmation: '',
      token: '',
    },
    loading: false,
    inviting: false,
    isRegistrationSuccess: false,
  }),

  watch: {
    inviting: {
      immediate: true,
      handler(isInviting) {
        if (isInviting) {
          this.$nextTick(() => {
            this.$refs.password.$refs.input.focus()
          })
          return
        }

        this.$nextTick(() => {
          this.$refs.fullName.focus()
        })
      },
    },
  },

  mounted() {
    this.recognizeInvitations()

    const dictionary = {
      custom: {
        confirm_password: {
          confirmed: 'The password confirmation does not match',
        },
      },
    }

    this.$validator.localize('en', dictionary)
  },

  methods: {
    async register(recaptchaToken) {
      try {
        await this.validate()

        if (!this.valid) return

        this.loading = true

        await this.$http.get('sanctum/csrf-cookie')

        await this.$http.post('register', {
          ...this.form,
          'g-recaptcha-response': recaptchaToken,
        })

        if (this.alreadyLoggedIn() && (await this.loginWithOldUser())) {
          return this.$router.push({ name: 'wall-chart' })
        }

        await this.$http.post('logout')
        await this.$store.dispatch('resetStates')

        this.isRegistrationSuccess = true

        this.$nextTick(() => this.$refs.recaptcha.execute())
      } catch ({ response }) {
        this.validateFromResponse(response, false)
        this.loading = false
      }
    },

    async loginRegisteredUser(recaptchaToken) {
      try {
        await this.login(
          {
            email: this.form.email,
            password: this.form.password,
            'g-recaptcha-response': recaptchaToken,
          },
          this.inviting
        )
      } catch ({ response }) {
        if (response.data.errors.sso_enforced) {
          this.$router.push({
            name: 'signin',
            params: {
              message: {
                type: 'error',
                content: response.data.errors.sso_enforced,
              },
            },
          })
        }
        this.validateFromResponse(response, false)
      }
      this.loading = false
    },

    async loginWithOldUser() {
      this.$modal.show('dialog', {
        title: `<span class="modal-header-icon error-icon-exclamation-triangle tw-text-red-500">Select User</span>`,
        text:
          'You are already logged in as ' +
          this.authUser.full_name +
          '. Choose the user account that you want to use.',
        buttons: [
          {
            title: this.authUser.full_name,
            handler: () => this.$emit('old-user-login'),
          },
          {
            title: this.form.full_name,
            handler: () => this.$emit('new-user-login'),
          },
        ],
      })

      return new Promise(resolve => {
        this.$on('old-user-login', () => {
          this.$modal.hide('dialog')
          resolve(true)
        })
        this.$on('new-user-login', () => {
          this.$modal.hide('dialog')
          resolve(false)
        })
      })
    },

    alreadyLoggedIn() {
      return this.$store.getters['auth/check']
    },

    async submit() {
      this.$refs.recaptcha.execute()
    },

    recognizeInvitations() {
      const query = this.$route.query
      const params = this.$route.params

      if ((query.invited === true || query.invited === 'true') && query.token) {
        this.inviting = true
        this.form.token = query.token
      }

      this.$nextTick(() => {
        this.form.email = params.employment
          ? params.employment.email
          : query.email
        this.form.full_name = params.employment
          ? params.employment.full_name
          : query.full_name

        this.$validator.reset()
      })
    },
  },
}
</script>
