<template>
  <div>
    <div class="card card-container">
      <div
        class="tw-flex tw-items-center tw-justify-between tw-flex-wrap tw-pt-10 tw-pb-5"
      >
        <div class="tw-w-full sm:tw-w-2/3">
          <div class="sm:tw-flex tw-items-center">
            <h2 class="page-title" data-cy="employees-title">Employees</h2>
          </div>
        </div>

        <div
          class="tw-w-full sm:tw-w-1/3 tw-mt-4 sm:tw-mt-0 sm:tw-text-right tw-flex tw-justify-end"
        >
          <SpinnerButton
            data-cy="add-employee"
            type="button"
            @click="newEmployee"
          >
            Add Employee
          </SpinnerButton>
        </div>
      </div>
      <div class="tw-w-full tw-mb-5">
        <div class="sm:tw-flex tw-items-center">
          <div
            class="tw-flex tw-items-center tw-justify-start tw-bg-white tw-rounded-md tw-mr-2 tw-border tw-border-gray-330 md:tw-mb-0 tw-mb-3"
            style="min-width: 180px;"
          >
            <DepartmentSwitcher
              with-no-department
              :is-all-dept-lable="true"
              :enable-group-by-department="false"
            />
          </div>
          <div
            class="tw-flex tw-items-center tw-justify-start tw-bg-white tw-rounded-md tw-mr-2 tw-border tw-border-gray-330 md:tw-mb-0 tw-mb-3"
            style="min-width: 180px;"
          >
            <RoleSwitcher />
          </div>
          <div
            class="tw-flex tw-items-center tw-justify-start tw-bg-white tw-rounded-md tw-mr-2 tw-border tw-border-gray-330 md:tw-mb-0 tw-mb-3"
            style="min-width: 180px;"
          >
            <StatusSwitcher />
          </div>
          <div class="tw-mr-2 md:tw-mb-0 tw-mb-3">
            <SearchBoxSwitcher />
          </div>
        </div>
      </div>
      <div class="tw-relative">
        <div v-scroll-hint class="table-responsive settings-table">
          <EmployeeTable
            :loading="employmentsLoading"
            :employments="paginatedEmployments.data"
            :inviting-employment="invitingEmployment"
            @edit-employment="editEmployee"
            @delete-employment="deleteEmployee"
            @restore-employment="restoreEmployment"
            @remind-invitation="remindOrInvite"
            @invite-employment="remindOrInvite"
            @send-invitation="sendInvitation"
          />
        </div>

        <div class="paginate-wrapper" data-cy="pagination">
          <Pagination
            :current-page="paginatedEmployments.current_page"
            :page-count="pageCount"
            :click-handler="fetchEmployments"
          />
        </div>
      </div>

      <Modal
        :classes="[
          'modal-overflow-visible',
          'tw-shadow-md',
          'tw-bg-white',
          'tw-rounded-lg',
        ]"
        :max-width="800"
        name="employee-form"
        data-cy="employee-form"
        width="95%"
        height="auto"
        adaptive
        scrollable
      >
        <div class="modal-header">
          <div class="tw-flex tw-justify-between">
            <div>
              <div
                v-if="editing"
                class="modal-title tw-flex tw-items-center"
                data-cy="edit-emp-header"
              >
                <p>
                  <span>{{ employment.full_name }}</span>
                  <span
                    v-if="employment.department"
                    class="tw-border-l-2 tw tw-ml-2 tw-pl-2 tw-text-sm"
                    >{{ employment.department.name }}</span
                  >
                </p>
              </div>
              <div v-else class="modal-title">New Employee</div>
            </div>
            <div>
              <button
                class="modal-close"
                data-cy="employee-close"
                @click="$modal.hide('employee-form')"
              >
                <SvgIcon name="close" class="tw-w-4 tw-h-4" />
              </button>
            </div>
          </div>
        </div>

        <div class="ld-tab">
          <span
            :class="{ 'modal-active-tab': activeTab === 'profile' }"
            class="ld-tab-item"
            data-cy="emp-profile-tab"
            @click="activeTab = 'profile'"
            >Profile</span
          >
          <span
            v-if="editing"
            :class="{ 'modal-active-tab': activeTab === 'schedule' }"
            class="ld-tab-item"
            data-cy="emp-schedule-tab"
            @click="activeTab = 'schedule'"
            >Schedule</span
          >
          <span
            v-if="editing"
            :class="{ 'modal-active-tab': activeTab === 'allowances' }"
            class="ld-tab-item"
            data-cy="emp-allowances-tab"
            @click="activeTab = 'allowances'"
            >Allowances</span
          >
        </div>

        <div v-if="activeTab === 'profile'" class="tw-w-full ld-tab-container">
          <div class="tw-mt-3 tw-p-3" data-cy="employee-popup">
            <form class="tw-w-full" @submit.prevent="submitEmployee">
              <div class="sm:tw-flex">
                <div class="tw-flex-1">
                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="job_title"
                        >Job Title</label
                      >
                      <input
                        id="job_title"
                        v-model="form.job_title"
                        v-validate="'max:50'"
                        v-focus
                        name="job_title"
                        data-vv-as="job title"
                        class="form-control"
                        type="text"
                        tabindex="1"
                        autocomplete="off"
                        data-cy="job-title"
                      />
                      <p
                        v-show="errors.has('job_title')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('job_title') }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="full_name">
                        Full Name <span class="required-field">&#42;</span>
                      </label>
                      <input
                        id="full_name"
                        v-model="form.full_name"
                        v-validate="'required|max:61'"
                        maxlength="61"
                        name="full_name"
                        data-vv-as="full name"
                        class="form-control"
                        data-cy="full-name"
                        type="text"
                        tabindex="2"
                        autocomplete="off"
                      />
                      <p
                        v-show="errors.has('full_name')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('full_name') }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="date_of_birth">
                        Date of Birth
                      </label>
                      <SingleDatePicker
                        id="date_of_birth"
                        v-model="form.date_of_birth"
                        v-validate:dateOfBirth="dateRules"
                        :format="dateFormatWithSlashByLocale"
                        :placeholder="dateFormatWithSlashByLocale"
                        parent-el="#employee-form"
                        data-vv-as="date of birth"
                        name="date_of_birth"
                        data-cy="date-of-birth"
                        class="form-control"
                        type="text"
                        tabindex="4"
                        autocomplete="off"
                        inline-picker
                      />
                      <p
                        v-show="errors.has('date_of_birth')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('date_of_birth') }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label tw-flex-1" for="email">
                        Email
                      </label>
                      <input
                        id="email"
                        v-model="form.email"
                        v-validate="'email'"
                        data-cy="email"
                        name="email"
                        class="form-control"
                        placeholder="john@example.com"
                        type="email"
                        inputmode="email"
                        tabindex="5"
                        autocomplete="off"
                      />
                      <p
                        v-show="errors.has('email')"
                        data-cy="email-error"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('email') }}
                      </p>
                    </div>
                    <div
                      v-if="hasEmail && hasJoinedCompany"
                      class="tw-px-3 tw-mt-3"
                    >
                      <SpinnerButton
                        :disabled="passwordResetLinkSending"
                        :loading="passwordResetLinkSending"
                        :spinner-only="true"
                        :spinner-classes="['tw-h-2 tw-w-2 tw-text-blue-500']"
                        as="link"
                        class="tw-p-0 tw-text-sm"
                        type="button"
                        cypress-attribute="link-reset-password"
                        @click="resetPassword"
                      >
                        Reset Password
                      </SpinnerButton>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="department">
                        Department
                      </label>
                      <div class="tw-relative">
                        <DepartmentPicker
                          id="department"
                          v-model="form.department_id"
                          :options="selectableDepartments"
                          :reduce="option => option.id"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="employee_code">
                        Employee Code
                      </label>
                      <input
                        id="employee_code"
                        v-model="form.employee_code"
                        v-validate="'max:20'"
                        maxlength="20"
                        name="employee_code"
                        data-cy="employee-code"
                        class="form-control"
                        type="text"
                        tabindex="7"
                        autocomplete="off"
                      />
                      <p
                        v-show="errors.has('employee_code')"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('employee_code') }}
                      </p>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="approvers">
                        <div class="tw-flex tw-items-center">
                          Approver(s)
                          <ExtraInfo icon="question">
                            <div class="tw-p-4 tw-w-48">
                              Select who should approve requests for this
                              employee. This will override any approvers set up
                              for the department.
                              <div>
                                <a
                                  class="btn-link tw-font-semibold"
                                  target="_blank"
                                  href="https://help.leavedates.com/hc/en-us/articles/360008866160-How-to-set-up-who-approves-new-leave-requests"
                                  >More info</a
                                >
                              </div>
                            </div>
                          </ExtraInfo>
                        </div>
                      </label>
                      <EmployeePicker
                        v-model="form.selectedApprovers"
                        :options="approverEmployments.data"
                        :loading="isApproversLoading"
                        :has-next-page="hasNextPageForApprovers"
                        :not-found-message="'No approvers available'"
                        data-cy="approvers"
                        @search-change="name => (approverSearchName = name)"
                        @scroll-down="fetchMoreApprovers"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="followers">
                        <div class="tw-flex tw-items-center">
                          Notify
                          <ExtraInfo icon="question">
                            <div class="tw-p-4 tw-w-48">
                              Select who should be notified of new requests for
                              this employee. This is in addition to anyone set
                              up to receive notifications for the department.
                              They will not have access to approve requests.
                              <div>
                                <a
                                  class="btn-link tw-font-semibold"
                                  target="_blank"
                                  href="https://help.leavedates.com/hc/en-us/articles/4412373021330"
                                  >More info</a
                                >
                              </div>
                            </div>
                          </ExtraInfo>
                        </div>
                      </label>
                      <EmployeePicker
                        v-model="form.selectedFollowers"
                        :options="followerEmployments.data"
                        :loading="isFollowersLoading"
                        :has-next-page="hasNextPageForFollowers"
                        :not-found-message="'No notifiers available'"
                        data-cy="notifiers"
                        @search-change="name => (followerSearchName = name)"
                        @scroll-down="fetchMoreFollowers"
                      />
                    </div>
                  </div>
                </div>

                <div class="tw-flex-1">
                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="start_date"
                        >Employment start</label
                      >
                      <SingleDatePicker
                        id="start_date"
                        v-model="form.start_date"
                        v-validate:startDate="dateRules"
                        :format="dateFormatWithSlashByLocale"
                        :placeholder="dateFormatWithSlashByLocale"
                        parent-el="#employee-form"
                        data-vv-as="start date"
                        name="start_date"
                        data-cy="start-date"
                        class="form-control"
                        type="text"
                        tabindex="9"
                        autocomplete="off"
                        inline-picker
                      />
                      <p
                        v-show="errors.has('start_date')"
                        data-cy="email-error"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('start_date') }}
                      </p>
                    </div>
                    <div
                      v-if="
                        isStartOrEndDatesNotChanged &&
                          isStartDateAvailableForProrate &&
                          isEmploymentStartWithingCurrentAndLastCalendar &&
                          hasBreakdownsToProrateInEmploymentStartCalendar &&
                          !isEmploymentStartCalendarProrated
                      "
                      class="tw-w-full tw-mt-2 tw-px-3"
                    >
                      <div
                        class="tw-bg-orange-200 tw-bg-opacity-50 tw-border tw-border-orange-400 tw-rounded-lg
                     tw-overflow-hidden tw-text-orange-500"
                      >
                        <div class="tw-py-2 tw-px-2 tw-flex tw-items-center">
                          <div class="tw-mx-2">
                            <SvgIcon
                              name="exclamation-warning-triangle-solid"
                              class="icon icon-close tw-w-5 tw-h-5 tw-text-gray-700"
                            />
                          </div>
                          <div class="tw-mx-2">
                            <button
                              class="tw-font-semibold tw-border-b tw-border-orange-500 focus:tw-border-orange-500 tw-text-thin tw-text-left"
                              @click.prevent="showStartDateProrationModal"
                            >
                              Calculate prorated allowances based on start date
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="
                      activeCompany.allow_calendar_align_to_start_date &&
                        !employeeStartDateIsSameAsCalendarStartDate
                    "
                    class="form-group"
                  >
                    <div class="tw-w-full tw-px-3">
                      <div class="tw-w-full">
                        <input
                          id="align_allowance_to_start_date"
                          v-model="form.align_allowance_to_start_date"
                          :disabled="!startDate"
                          class="magic-checkbox"
                          name="align_allowance_to_start_date"
                          data-cy="align_allowance_to_start_date"
                          type="checkbox"
                          tabindex="15"
                          @change="alignStartDate"
                        />
                        <label
                          for="align_allowance_to_start_date"
                          class="tw-select-none magic-checkbox-label"
                        >
                          <div class="tw-flex tw-items-center">
                            <span>Align leave year to start date</span>
                            <ExtraInfo
                              :class="{ 'tw-opacity-50': !startDate }"
                              icon="question"
                            >
                              <div class="tw-p-4 tw-w-48">
                                Align the leave year for this employee to begin
                                on the day and month of their start date.
                                <div>
                                  <a
                                    class="btn-link tw-font-semibold"
                                    target="_blank"
                                    href="https://help.leavedates.com/hc/en-us/articles/360008866180-How-to-align-the-leave-year-with-an-employee-s-start-date"
                                    >More info</a
                                  >
                                </div>
                              </div>
                            </ExtraInfo>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="end_date"
                        >Employment end</label
                      >
                      <SingleDatePicker
                        id="end_date"
                        v-model="form.end_date"
                        v-validate:endDate="dateRules"
                        :format="dateFormatWithSlashByLocale"
                        :placeholder="dateFormatWithSlashByLocale"
                        parent-el="#employee-form"
                        data-vv-as="end date"
                        name="end_date"
                        data-cy="end-date"
                        class="form-control"
                        type="text"
                        tabindex="10"
                        autocomplete="off"
                        inline-picker
                      />
                      <p
                        v-show="errors.has('end_date')"
                        data-cy="email-error"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('end_date') }}
                      </p>
                    </div>
                    <div
                      v-if="
                        isStartOrEndDatesNotChanged &&
                          isEndDateAvailableForProrate &&
                          isEmploymentEndWithingCurrentAndLastCalendar &&
                          hasBreakdownsToProrateInEmploymentEndCalendar &&
                          !isEmploymentEndCalendarProrated
                      "
                      class="tw-w-full tw-mt-2 tw-px-3"
                    >
                      <div
                        class="tw-bg-orange-200 tw-bg-opacity-50 tw-border tw-border-orange-400 tw-rounded-lg
                     tw-overflow-hidden tw-text-orange-500"
                      >
                        <div class="tw-py-2 tw-px-2 tw-flex tw-items-center">
                          <div class="tw-mx-2">
                            <SvgIcon
                              name="exclamation-warning-triangle-solid"
                              class="icon icon-close tw-w-5 tw-h-5 tw-text-gray-700"
                            />
                          </div>
                          <div class="tw-mx-2">
                            <button
                              class="tw-font-semibold tw-border-b tw-border-orange-500 focus:tw-border-orange-500 tw-text-thin tw-text-left"
                              @click.prevent="showEndDateProrationModal"
                            >
                              Calculate prorated allowances based on end date
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="timezone">
                        Timezone <span class="required-field">&#42;</span>
                      </label>
                      <div class="tw-relative">
                        <VSelect
                          id="timezone"
                          v-model="form.timezone"
                          v-validate="'required'"
                          :options="timezones"
                          :show-labels="false"
                          :tabindex="11"
                          :allow-empty="false"
                          :max-height="180"
                          placeholder="Select a timezone"
                          name="timezone"
                          data-cy="timezone"
                          label="name"
                          track-by="name"
                        >
                          <template slot="option" slot-scope="props">
                            <div
                              class="tw-flex tw-justify-start tw-items-center tw-content-center"
                            >
                              <div class="tw-text-base tw-font-normal">
                                ({{ props.option.offset }})
                                {{ props.option.name }}
                              </div>
                            </div>
                          </template>
                          <template slot="noResult">
                            <span>No items found.</span>
                          </template>
                        </VSelect>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="holiday-location">
                        Public Holidays
                      </label>
                      <div class="tw-relative">
                        <VSelect
                          id="holiday-location"
                          v-model="selectedHolidayLocation"
                          :options="selectableHolidayLocations"
                          :multiple="false"
                          :searchable="false"
                          :show-labels="false"
                          :allow-empty="false"
                          :tabindex="12"
                          :max-height="180"
                          placeholder=""
                          data-cy="holiday-location"
                          name="holiday-location"
                        >
                          <template slot="option" slot-scope="props">
                            <CompanyHolidayLocation :location="props.option" />
                          </template>
                          <template slot="singleLabel" slot-scope="props">
                            <CompanyHolidayLocation :location="props.option" />
                          </template>
                        </VSelect>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label">
                        Allowance Unit <span class="required-field">&#42;</span>
                      </label>
                    </div>
                    <div
                      class="tw-w-full tw-px-3 tw-mt-2"
                      cy-data="allowance-unit-options"
                    >
                      <div class="tw-inline-block tw-mr-4">
                        <input
                          id="allowance_unit_is_days"
                          v-model="allowanceUnitIsDays"
                          :value="true"
                          class="magic-radio"
                          type="radio"
                          name="allowance_unit_is_days"
                          data-cy="allowance-unit"
                          tabindex="13"
                        />
                        <label
                          class="magic-radio-label"
                          for="allowance_unit_is_days"
                          >Days</label
                        >
                      </div>
                      <div class="tw-inline-block tw-mr-4">
                        <input
                          id="allowance_unit_in_hours"
                          v-model="allowanceUnitIsDays"
                          :value="false"
                          class="magic-radio"
                          type="radio"
                          name="allowance_unit_is_days"
                          data-cy="allowance-unit-hours"
                          tabindex="14"
                        />
                        <label
                          class="magic-radio-label"
                          for="allowance_unit_in_hours"
                          >Hours</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3">
                      <label class="form-label" for="visible_scopes_picker">
                        <div class="tw-flex tw-items-center">
                          <span>This person can see</span>
                          <ExtraInfo icon="question">
                            <div class="tw-p-4 tw-w-48">
                              Manage who they can see in the wall chart. Setting
                              this here will override what has been set for
                              their department.
                              <div>
                                <a
                                  class="btn-link tw-font-semibold"
                                  target="_blank"
                                  href="https://help.leavedates.com/hc/en-us/articles/360010559980-Control-what-staff-can-see-in-the-wall-chart"
                                  >More info</a
                                >
                              </div>
                            </div>
                          </ExtraInfo>
                        </div>
                      </label>

                      <VisibilityScopesPicker
                        id="visible_scopes_picker"
                        v-model="form.visible_scopes"
                        :departments="departments"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="tw-w-full tw-px-3 tw-mb-2">
                      <label class="form-label">Roles</label>
                    </div>
                    <div class="tw-w-full tw-mb-3 tw-px-3">
                      <input
                        id="is_admin"
                        v-model="form.is_admin"
                        v-validate="''"
                        class="magic-checkbox"
                        name="is_admin"
                        data-cy="is-admin"
                        type="checkbox"
                        tabindex="15"
                      />
                      <label
                        for="is_admin"
                        class="tw-select-none magic-checkbox-label"
                      >
                        <div class="tw-flex tw-items-center">
                          Admin
                          <ExtraInfo icon="question">
                            <div class="tw-p-4 tw-w-48">
                              This gives the user full access to all
                              administration screens, to set up and configure
                              the calendar as well as administer employee
                              changes. Admin users also have full access to view
                              information of all employees.
                              <div>
                                <a
                                  class="btn-link tw-font-semibold"
                                  target="_blank"
                                  href="https://help.leavedates.com/hc/en-us/articles/360002592700-What-are-the-different-user-roles-and-what-permissions-does-each-have-"
                                  >More info</a
                                >
                              </div>
                            </div>
                          </ExtraInfo>
                        </div>
                      </label>
                      <p
                        v-show="errors.has('is_admin')"
                        data-cy="email-error"
                        class="tw-mt-2 tw-text-red-700 tw-text-sm"
                      >
                        {{ errors.first('is_admin') }}
                      </p>
                    </div>
                    <div class="tw-w-full tw-mb-3 tw-px-3">
                      <input
                        id="is_approver"
                        v-model="form.is_approver"
                        class="magic-checkbox"
                        name="is_approver"
                        data-cy="is-approver"
                        type="checkbox"
                        tabindex="16"
                        :disabled="editing && hasApprovables"
                      />
                      <label
                        for="is_approver"
                        class="tw-select-none magic-checkbox-label"
                      >
                        <div class="tw-flex tw-items-center">
                          Approver
                          <ExtraInfo v-if="!hasApprovables" icon="question">
                            <div class="tw-p-4 tw-w-48">
                              This gives the user access to approve leave
                              requests, as well as view the calendars and leave
                              records of their team members.
                              <div>
                                <a
                                  class="btn-link tw-font-semibold"
                                  target="_blank"
                                  href="https://help.leavedates.com/hc/en-us/articles/360002592700-What-are-the-different-user-roles-and-what-permissions-does-each-have-"
                                  >More info</a
                                >
                              </div>
                            </div>
                          </ExtraInfo>
                          <ExtraInfo
                            v-if="editing && hasApprovables"
                            icon="question"
                          >
                            <div class="tw-p-4 tw-w-48">
                              To remove this permission, first unassign them as
                              approver for any employees or departments. Use the
                              approver report to find out more
                              <div>
                                <RouterLink
                                  :to="{
                                    name: 'reports-approver',
                                    params: { approver: employment.id },
                                  }"
                                >
                                  <a
                                    class="btn-link tw-font-semibold"
                                    target="_blank"
                                    >View report</a
                                  >
                                </RouterLink>
                              </div>
                            </div>
                          </ExtraInfo>
                        </div>
                      </label>
                    </div>
                    <div class="tw-w-full tw-mb-3 tw-px-3">
                      <input
                        id="is_hidden"
                        v-model="form.is_hidden"
                        class="magic-checkbox"
                        name="is_hidden"
                        type="checkbox"
                        tabindex="16"
                        data-cy="is-hidden"
                      />
                      <label
                        for="is_hidden"
                        class="tw-select-none magic-checkbox-label"
                      >
                        <div class="tw-flex tw-items-center">
                          Hidden
                          <ExtraInfo icon="question">
                            <div class="tw-p-4 tw-w-48">
                              This hides the user from the wall chart for all
                              other users. It is useful for system
                              administrators or anyone who does not need to
                              share their leave with others.
                              <div>
                                <a
                                  class="btn-link tw-font-semibold"
                                  target="_blank"
                                  href="https://help.leavedates.com/hc/en-us/articles/360002592700-What-are-the-different-user-roles-and-what-permissions-does-each-have-"
                                  >More info</a
                                >
                              </div>
                            </div>
                          </ExtraInfo>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-flex tw-justify-end tw-mb-3 tw-px-3">
                <SpinnerButton
                  v-if="!form.user_id"
                  :disabled="!valid || loading || inviting || !form.email"
                  :loading="inviting"
                  :spinner-only="true"
                  :spinner-classes="['tw-h-2 tw-w-2 tw-text-blue-500']"
                  as="link"
                  type="button"
                  data-cy="save-invite-employee"
                  tabindex="17"
                  @click="submitWithInvitation"
                >
                  Save & Invite
                </SpinnerButton>

                <SpinnerButton
                  :disabled="!valid || loading || inviting"
                  :loading="loading && !inviting"
                  :spinner-only="true"
                  type="submit"
                  data-cy="save-employee"
                  tabindex="18"
                >
                  Save Profile
                </SpinnerButton>
              </div>
            </form>
          </div>
        </div>

        <div v-if="activeTab === 'schedule'" class="tw-w-full ld-tab-container">
          <div
            id="employment-working-schedule"
            class="tw-mt-3 tw-p-3"
            name="employment-working-schedule"
          >
            <div class="employment-working-schedule">
              <div class="tw-w-full tw-px-3">
                <form class="tw-mb-6">
                  <div class="form-group">
                    <div class="md:tw-w-1/3">
                      <label for="working_hours" class="form-label">
                        <div class="tw-flex tw-items-center">
                          Hours Per Working Day
                          <span class="required-field">&#42;</span>
                          <ExtraInfo icon="question">
                            <div class="tw-p-4 tw-w-48">
                              This is the number of hours which will be used
                              when the employee requests a full day of leave. If
                              they request a half day of leave then half of this
                              value will be used.
                              <div>
                                <a
                                  class="btn-link tw-font-semibold"
                                  target="_blank"
                                  href="https://help.leavedates.com/hc/en-us/articles/360002592660-Setting-up-employees"
                                  >More info</a
                                >
                              </div>
                            </div>
                          </ExtraInfo>

                          <HoursPerWorkingDayWarning
                            v-if="employmentWorkingSchedule"
                            :schedule-breakdowns="
                              employmentWorkingSchedule.schedule_breakdowns
                            "
                            :minutes-per-working-day="
                              form.minutes_per_working_day
                            "
                          />
                        </div>
                      </label>
                      <input
                        id="working_hours"
                        v-model="hoursPerWorkingDay"
                        v-validate="'required'"
                        class="form-control"
                        min="0"
                        max="24"
                        step="any"
                        data-vv-as="hours per working day"
                        data-cy="hours-per-working-day"
                        name="minutes_per_working_day"
                        type="number"
                        inputmode="decimal"
                        style="width: 90px;"
                      />
                      <p
                        v-show="errors.has('minutes_per_working_day')"
                        data-cy="error-hours-per-working-day"
                        class="tw-mt-1 tw-text-red-700 tw-text-sm"
                      >
                        The hours per working day must be a number between 1 and
                        24.
                      </p>
                    </div>
                  </div>

                  <div class="working-schedule-wrap">
                    <div class="tw-w-full tw-mb-4">
                      <label class="form-label"
                        >Working Week
                        <span class="required-field">&#42;</span></label
                      >
                    </div>
                    <WorkingSchedule
                      v-if="employment"
                      v-model="employmentWorkingSchedule"
                      @valid="scheduleValidate"
                    />
                  </div>
                </form>
              </div>
            </div>

            <div class="tw-flex tw-justify-end tw-mb-3 tw-px-3">
              <button
                v-if="usesOwnWorkingSchedule"
                class="btn btn-link tw-text-blue"
                data-cy="company-default-close"
                @click.prevent="deleteEmploymentWorkingSchedule"
              >
                Use Company Default
              </button>
              <SpinnerButton
                :disabled="!valid || !scheduleValid || loading"
                data-cy="save-emp-working-schedule"
                @click="saveEmploymentWorkingSchedule"
              >
                Save Schedule
              </SpinnerButton>
            </div>
          </div>
        </div>

        <div
          v-if="activeTab === 'allowances'"
          class="tw-w-full ld-tab-container"
        >
          <EmployeeAllowance
            :current-calendar="currentYearCalendar || nearestEmploymentCalendar"
            :employment="employment"
            :calendars="employmentCalendars"
            :hide-carried-to-next-allowance="true"
            @show-proration-modal="setProrationData"
            @employment-allowance-updated="fetchEmployment(employment.id)"
          />
        </div>
      </Modal>

      <Modal
        :classes="[
          'modal-overflow-visible',
          'tw-shadow-md',
          'tw-bg-white',
          'tw-rounded-lg',
        ]"
        :max-width="800"
        name="employee-allowance-proration"
        width="95%"
        height="auto"
        adaptive
        scrollable
        :click-to-close="false"
        @closed="prorationModalClosed"
      >
        <ProrateAllowanceModal
          v-if="canShowProrationModal"
          :employment="employment"
          :start-date="dataProrate.startDate"
          :start-date-changed="dataProrate.startDateChanged"
          :start-calendar="dataProrate.startCalendar"
          :end-date="dataProrate.endDate"
          :end-date-changed="dataProrate.endDateChanged"
          :end-calendar="dataProrate.endCalendar"
          @close-proration-modal="closeProrationModal"
          @employment-allowance-updated="dataProrate.allowanceUpdated = true"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment-timezone'
import Subscription from '@/mixins/Subscription'
import ActiveEmployment from '@/mixins/ActiveEmployment'
import ValidatesForm from '@/mixins/ValidatesForm'
import DepartmentSwitcher from '@/components/DepartmentSwitcher'
import FormatDate from '@/mixins/FormatDate'
import Pagination from '@/components/pagination/Pagination'
import EmployeesShortcuts from '@/mixins/page-shortcuts/settings/EmployeesShortcuts'
import HoursPerWorkingDayWarning from '@/components/working-schedule/HoursPerWorkingDayWarning'
import VisibilityScopesPicker from '@/components/departments/VisibilityScopesPicker'
import { filter, find, flatMap, merge, debounce, uniqBy } from 'lodash-es'
import EmployeeAllowanceCalendar from '@/components/employee-leave-allowance/EmployeeAllowanceCalendar'
import SearchBoxSwitcher from '@/components/SearchBoxSwitcher'
import SpinnerButton from '@/components/SpinnerButton'
import {
  Departments,
  Employments,
  Approvers,
  EmploymentWorkingSchedule,
  Calendars,
  Timezones,
  HolidayLocations,
} from '@/api'
import EmployeePicker from '@/components/EmployeePicker'

const EmployeeTable = () => import('@/components/employee/EmployeeTable')
const VSelect = () => import('vue-multiselect')
const ExtraInfo = () => import('@/components/ExtraInfo')
const SingleDatePicker = () => import('@/components/SingleDatePicker')
const WorkingSchedule = () =>
  import('@/components/working-schedule/WorkingSchedule.vue')
const EmployeeAllowance = () =>
  import('@/components/employee-leave-allowance/EmployeeLeaveAllowances')
const ProrateAllowanceModal = () =>
  import('@/components/employment-allowances/ProrateAllowanceModal')
const CompanyHolidayLocation = () =>
  import('@/components/CompanyHolidayLocation')
const DepartmentPicker = () => import('@/components/pickers/DepartmentPicker')
const RoleSwitcher = () => import('@/components/RoleSwitcher')
const StatusSwitcher = () => import('@/components/StatusSwitcher')

const PAGINATED_EMPLOYMENTS = {
  data: [],
  total: 0,
  per_page: 15,
  current_page: 1,
}

export default {
  name: 'Employees',

  components: {
    EmployeePicker,
    SearchBoxSwitcher,
    CompanyHolidayLocation,
    VSelect,
    Pagination,
    ExtraInfo,
    SingleDatePicker,
    EmployeeTable,
    WorkingSchedule,
    DepartmentPicker,
    EmployeeAllowance,
    DepartmentSwitcher,
    VisibilityScopesPicker,
    HoursPerWorkingDayWarning,
    ProrateAllowanceModal,
    RoleSwitcher,
    StatusSwitcher,
    SpinnerButton,
  },

  mixins: [
    ValidatesForm,
    Subscription,
    EmployeesShortcuts,
    FormatDate,
    ActiveEmployment,
  ],

  data: () => ({
    activeTab: 'profile',
    employment: {
      full_name: '',
      minutes_per_working_day: null,
    },
    calendars: [],
    paginatedEmployments: {
      ...PAGINATED_EMPLOYMENTS,
    },
    followerEmployments: {
      ...PAGINATED_EMPLOYMENTS,
    },
    approverEmployments: {
      ...PAGINATED_EMPLOYMENTS,
    },
    followerSearchName: '',
    approverSearchName: '',
    departments: [],
    timezones: [],
    companyHolidayLocations: [],
    employmentWorkingSchedule: {},
    loading: false,
    editing: false,
    inviting: false,
    employmentsLoading: false,
    scheduleValid: true,
    form: {
      job_title: '',
      full_name: '',
      date_of_birth: '',
      email: '',
      timezone: '',
      holiday_location: '',
      employee_code: '',
      department_id: '',
      start_date: '',
      is_admin: false,
      is_approver: false,
      is_hidden: false,
      end_date: '',
      user_id: '',
      minutes_per_working_day: '',
      allowance_unit_is_days: null,
      align_allowance_to_start_date: false,
      visible_scopes: null,
      selectedFollowers: [],
      selectedApprovers: [],
    },
    dataProrate: {
      enabled: false,
      allowanceUpdated: false,
      startDate: null,
      startDateChanged: false,
      startCalendar: null,
      endDate: null,
      endDateChanged: false,
      endCalendar: null,
    },
    isFollowersLoading: false,
    isApproversLoading: false,
    passwordResetLinkSending: false,
    invitingEmployment: null,
  }),
  computed: {
    pageCount() {
      return Math.ceil(
        this.paginatedEmployments.total / this.paginatedEmployments.per_page
      )
    },

    startDate() {
      if (this.form.start_date instanceof moment) {
        return this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
          this.form.start_date
        )
      }
      return this.form.start_date
    },

    endDate() {
      if (this.form.end_date instanceof moment) {
        return this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
          this.form.end_date
        )
      }
      return this.form.end_date
    },

    momentStartDate() {
      if (!this.form.start_date) {
        return null
      }

      return moment(this.form.start_date, this.dateFormatWithDashByLocale)
    },

    momentEndDate() {
      if (!this.form.end_date) {
        return null
      }

      return moment(this.form.end_date, this.dateFormatWithDashByLocale)
    },

    momentEmploymentStartDate() {
      if (!this.editing || !this.employment.start_date) {
        return null
      }

      return moment.utc(this.employment.start_date)
    },

    momentEmploymentEndDate() {
      if (!this.editing || !this.employment.end_date) {
        return null
      }

      return moment.utc(this.employment.end_date)
    },

    isStartDatesEquals() {
      return (
        this.momentEmploymentStartDate &&
        this.momentStartDate &&
        this.momentEmploymentStartDate.isSame(
          this.momentStartDate.clone().utc(true),
          'date'
        )
      )
    },

    isEndDatesEquals() {
      return (
        this.momentEmploymentEndDate &&
        this.momentEndDate &&
        this.momentEmploymentEndDate.isSame(
          this.momentEndDate.clone().utc(true),
          'date'
        )
      )
    },

    isStartDatesAndAlignLeaveYearNotChanged() {
      return (
        this.isStartDatesEquals &&
        this.employment.align_allowance_to_start_date ===
          this.form.align_allowance_to_start_date
      )
    },

    employmentStartCalendar() {
      return (
        this.momentEmploymentStartDate &&
        this.getOverlappingCalendarForDate(
          this.employmentCalendars,
          this.momentEmploymentStartDate
        )
      )
    },

    employmentEndCalendar() {
      return (
        this.momentEmploymentEndDate &&
        this.getOverlappingCalendarForDate(
          this.employmentCalendars,
          this.momentEmploymentEndDate
        )
      )
    },

    isInTheSameCalendar() {
      return (
        this.employmentStartCalendar &&
        this.employmentEndCalendar &&
        this.employmentStartCalendar.id === this.employmentEndCalendar.id
      )
    },

    isStartOrEndDatesNotChanged() {
      if (this.momentEmploymentStartDate && this.momentEmploymentEndDate) {
        return (
          this.isStartDatesAndAlignLeaveYearNotChanged && this.isEndDatesEquals
        )
      }

      return (
        (this.momentEmploymentStartDate &&
          this.isStartDatesAndAlignLeaveYearNotChanged) ||
        (this.momentEmploymentEndDate && this.isEndDatesEquals)
      )
    },

    isStartDateAvailableForProrate() {
      return (
        this.editing &&
        this.employmentStartCalendar &&
        !this.isEmploymentStartDateEqualsStartCalendarStartDate &&
        !this.employment.align_allowance_to_start_date &&
        this.isStartDatesAndAlignLeaveYearNotChanged
      )
    },

    isEndDateAvailableForProrate() {
      return (
        this.editing &&
        this.momentEmploymentEndDate &&
        this.isEndDatesEquals &&
        !this.isEmploymentEndDateEqualsEndCalendarEndDate
      )
    },

    isEmploymentStartWithingCurrentAndLastCalendar() {
      return (
        this.employmentStartCalendar &&
        this.employmentAllowanceCalendar
          .getCurrentAndNextCalendars()
          .some(calendar => calendar.id === this.employmentStartCalendar.id)
      )
    },

    isEmploymentEndWithingCurrentAndLastCalendar() {
      return (
        this.employmentEndCalendar &&
        this.employmentAllowanceCalendar
          .getCurrentAndNextCalendars()
          .some(calendar => calendar.id === this.employmentEndCalendar.id)
      )
    },

    isEmploymentStartDateEqualsStartCalendarStartDate() {
      return (
        this.momentEmploymentStartDate &&
        this.employmentStartCalendar &&
        this.momentEmploymentStartDate.isSame(
          this.employmentStartCalendar.start_date,
          'date'
        )
      )
    },

    isEmploymentEndDateEqualsEndCalendarEndDate() {
      return (
        this.employmentEndCalendar &&
        this.momentEmploymentEndDate &&
        this.momentEmploymentEndDate.isSame(
          this.employmentEndCalendar.end_date,
          'date'
        )
      )
    },

    dateOfBirth() {
      if (this.form.date_of_birth instanceof moment) {
        return this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
          this.form.date_of_birth
        )
      }
      return this.form.date_of_birth
    },

    dateFormatWithSlashByLocale() {
      return this.getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash()
    },

    dateFormatWithDashByLocale() {
      return this.getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithDash()
    },

    dateRules() {
      if (this.authUser.locale === 'en_US') {
        return 'date_format:MM/dd/yyyy'
      }

      return 'date_format:dd/MM/yyyy'
    },

    usesOwnWorkingSchedule() {
      return (
        this.employment &&
        this.employment.available_working_schedule &&
        this.employment.available_working_schedule.schedulable_id ===
          this.employment.id
      )
    },

    needsToUpgrade() {
      return (
        this.activeCompany.employments_count >= 5 &&
        (!this.isSubscribed ||
          this.activeCompany.employments_count >= this.numberOfSeats)
      )
    },

    employmentAllowanceCalendar() {
      return new EmployeeAllowanceCalendar(this.employment, this.calendars)
    },

    employmentCalendars() {
      return this.employmentAllowanceCalendar.getEmployeeCalendars()
    },

    nearestEmploymentCalendar() {
      return this.employmentAllowanceCalendar.getNearestCalendar()
    },

    employeeStartDateIsSameAsCalendarStartDate() {
      return (
        this.form.start_date !== '' &&
        moment(
          this.form.start_date,
          this.getFormatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash()
        )
          .utc(true)
          .format('MM-DD') ===
          moment.utc(this.calendars[0].start_date).format('MM-DD')
      )
    },

    currentYearCalendar() {
      return this.employmentAllowanceCalendar.getCurrentYearCalendar()
    },

    allowanceUnitIsDays: {
      get() {
        const employeeAllowanceUnitIsDays = this.form.allowance_unit_is_days
        if (employeeAllowanceUnitIsDays === null) {
          return this.activeCompany.allowance_unit_is_days
        }
        return employeeAllowanceUnitIsDays
      },
      set(val) {
        this.form.allowance_unit_is_days = val
      },
    },

    minutesPerWorkingDay() {
      if (this.employment.minutes_per_working_day != null) {
        return this.employment.minutes_per_working_day
      }
      return this.activeCompany.minutes_per_working_day
    },

    hoursPerWorkingDay: {
      get() {
        const minutes = this.form.minutes_per_working_day
        return minutes ? minutes / 60 : null
      },
      set(hours) {
        this.form.minutes_per_working_day = hours ? hours * 60 : 0
      },
    },

    visibleScopes() {
      const scopes = [...new Set(this.form.visible_scopes)]

      return scopes && scopes.length ? scopes : null
    },

    selectableDepartments() {
      return [
        {
          id: '',
          name: '--',
        },
        ...this.departments,
      ]
    },

    selectableHolidayLocations() {
      return [
        {
          name: '',
          country: '--',
        },
        ...this.companyHolidayLocations,
      ]
    },

    selectedHolidayLocation: {
      get() {
        if (!this.form.holiday_location) {
          return this.selectableHolidayLocations[0]
        }

        return find(this.selectableHolidayLocations, holidayLocation => {
          return (
            this.form.holiday_location.toLowerCase() ===
            holidayLocation.name.toLowerCase()
          )
        })
      },
      set(val) {
        this.form.holiday_location = val.name.toLowerCase()
      },
    },

    canShowProrationModal() {
      return (
        this.dataProrate.enabled &&
        ((this.dataProrate.startDate && this.dataProrate.startCalendar) ||
          this.dataProrate.endDate)
      )
    },

    isEmploymentStartCalendarProrated() {
      return (
        this.employmentStartCalendar &&
        this.hasProratedBreakdowns(this.employmentStartCalendar)
      )
    },

    isEmploymentEndCalendarProrated() {
      return (
        this.employmentEndCalendar &&
        this.hasProratedBreakdowns(this.employmentEndCalendar)
      )
    },

    hasBreakdownsToProrateInEmploymentStartCalendar() {
      return (
        this.employmentStartCalendar &&
        this.getBreakdownsForProration(this.employmentStartCalendar).length
      )
    },

    hasBreakdownsToProrateInEmploymentEndCalendar() {
      return (
        this.employmentEndCalendar &&
        this.getBreakdownsForProration(this.employmentEndCalendar).length
      )
    },

    hasNextPageForFollowers() {
      return (
        Math.ceil(
          this.followerEmployments.total / this.followerEmployments.per_page
        ) > this.followerEmployments.current_page
      )
    },

    hasNextPageForApprovers() {
      return (
        Math.ceil(
          this.approverEmployments.total / this.approverEmployments.per_page
        ) > this.approverEmployments.current_page
      )
    },

    hasEmail() {
      return this.employment.email !== null
    },

    hasJoinedCompany() {
      return this.employment.status === 'Joined'
    },

    hasApprovables() {
      return this.employment.has_approvables
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetchCalendars()
        this.fetchApprovers()
        this.resetPagination()
        this.fetchEmployments()
        this.resetFollowerEmployments()
        this.fetchFollowers()
        this.resetApproverEmployments()
        this.fetchApprovers()
        this.fetchDepartments()
        this.fetchCompanyHolidayLocations()

        if (this.$route.params.employment_id) {
          this.editEmployee({ id: this.$route.params.employment_id })
        }
      },
    },

    '$route.query.status': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.resetPagination()
        this.fetchEmployments()
      },
    },

    '$route.query.search': {
      immediate: true,
      handler: debounce(function(newVal, oldVal) {
        if (newVal === oldVal) return

        this.resetPagination()
        this.fetchEmployments()
      }, 500),
    },

    '$route.query.department': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.resetPagination()
        this.fetchEmployments()
      },
    },

    '$route.query.role': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.resetPagination()
        this.fetchEmployments()
      },
    },

    startDate(date) {
      if (!date || this.employeeStartDateIsSameAsCalendarStartDate) {
        this.form.align_allowance_to_start_date = false
      }
    },

    followerSearchName: {
      handler(value) {
        this.resetFollowerEmployments()
        this.fetchFollowers(value)
      },
    },

    approverSearchName: {
      handler(value) {
        this.resetApproverEmployments()
        this.fetchApprovers(value)
      },
    },
  },

  created() {
    this.fetchTimezones()
  },

  methods: {
    ...mapActions('auth', ['fetchUser', 'fetchCompany']),

    refreshSelectedWorkingSchedule() {
      this.employmentWorkingSchedule = {
        ...this.employment.available_working_schedule,
      }
    },

    async deleteEmploymentWorkingSchedule() {
      await EmploymentWorkingSchedule.delete(
        this.employment.available_working_schedule.id,
        this.activeCompany.id
      )

      await this.fetchEmployment(this.employment.id)
      this.refreshSelectedWorkingSchedule()

      this.success('Working schedule changed to company default successfully.')
    },

    async remindOrInvite(employee) {
      this.invitingEmployment = employee

      await this.sendInvitation(employee)

      await this.fetchEmployments()

      this.invitingEmployment = null
    },

    fetchApprovers: debounce(async function(name) {
      this.isApproversLoading = true

      const { data } = await Approvers.find({
        search: name,
        company: this.activeCompany.id,
        page: this.approverEmployments.current_page,
      })

      this.approverEmployments = {
        ...data,
        data: uniqBy([...this.approverEmployments.data, ...data.data], 'id'),
      }

      this.isApproversLoading = false
    }, 300),

    async fetchEmployments(page) {
      this.employmentsLoading = true
      try {
        const { data } = await Employments.find({
          ...this.$route.query,
          page: page || this.paginatedEmployments.current_page,
        })

        this.paginatedEmployments = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
      this.employmentsLoading = false
    },

    resetFollowerEmployments: function() {
      this.followerEmployments = {
        ...PAGINATED_EMPLOYMENTS,
      }
    },

    resetApproverEmployments: function() {
      this.approverEmployments = {
        ...PAGINATED_EMPLOYMENTS,
      }
    },

    fetchFollowers: debounce(async function(name) {
      this.isFollowersLoading = true
      const { data } = await Employments.find({
        search: name,
        company: this.activeCompany.id,
        page: this.followerEmployments.current_page,
      })

      this.followerEmployments = {
        ...data,
        data: uniqBy([...this.followerEmployments.data, ...data.data], 'id'),
      }

      this.isFollowersLoading = false
    }, 300),

    fetchMoreFollowers(reached) {
      if (reached) {
        this.followerEmployments.current_page++
        this.fetchFollowers(this.followerSearchName)
      }
    },

    fetchMoreApprovers(reached) {
      if (reached) {
        this.approverEmployments.current_page++
        this.fetchApprovers(this.approverSearchName)
      }
    },

    async fetchEmployment(employmentKey) {
      try {
        const { data } = await Employments.get(employmentKey, {
          company_id: this.activeCompany.id,
        })

        this.employment = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchDepartments() {
      try {
        const { data } = await Departments.all(this.$route.query)

        this.departments = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchCalendars() {
      try {
        const { data } = await Calendars.get(this.$route.query)

        this.calendars = data.reverse()
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchTimezones() {
      try {
        const { data } = await Timezones.all()

        this.timezones = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchCompanyHolidayLocations() {
      try {
        this.companyHolidayLocations = await HolidayLocations.all({
          params: {
            company_id: this.activeCompany.id,
          },
        })
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async submitWithInvitation() {
      this.inviting = true

      await this.submitEmployee()

      this.inviting = false
    },

    async submitEmployee() {
      await this.validate()

      if (!this.valid || !(await this.confirmEmailChange())) return

      try {
        this.loading = true

        const employment = await this.submitEmployeePayload()

        await this.fetchEmployment(employment.id)

        this.fillEmployeeForm()

        this.editing = true

        this.success('Employee saved successfully.')

        this.loading = false

        return new Promise(resolve => {
          resolve(employment)
        })
      } catch ({ response }) {
        this.loading = false

        this.validateFromResponse(response, false)

        return new Promise(reject => {
          reject(response)
        })
      }
    },

    async confirmEmailChange() {
      if (
        this.employment.user_id &&
        (this.form.email === null || this.form.email === '')
      ) {
        let message =
          (this.isActiveEmployment(this.employment.id)
            ? 'You'
            : this.form.full_name) +
          ' will not be able to login once ' +
          (this.isActiveEmployment(this.employment.id) ? 'your' : 'their') +
          ' email has been removed. Are you sure you want to save?'

        return await this.confirm(message, 'Email removed')
      }

      return true
    },

    async submitEmployeePayload() {
      const followers = this.form.selectedFollowers.map(follower => follower.id)
      const approvers = this.form.selectedApprovers.map(approver => approver.id)

      const payload = {
        ...this.form,
        followers,
        approvers,
        timezone: this.form.timezone.name,
        start_date: this.form.start_date
          ? this.formatYear(this.form.start_date)
          : '',
        end_date: this.form.end_date ? this.formatYear(this.form.end_date) : '',
        date_of_birth: this.form.date_of_birth
          ? this.formatYear(this.form.date_of_birth)
          : '',
        company_id: this.activeCompany.id,
        visible_scopes: this.visibleScopes,
      }

      try {
        const { data } = this.editing
          ? await Employments.update(this.employment.id, payload)
          : await Employments.create(payload)

        await this.fetchUser()
        await this.fetchCompany(this.activeCompany)

        if (this.inviting) {
          await this.sendInvitation(data)
        }

        await Promise.all([this.fetchEmployments(), this.fetchApprovers()])

        return data
      } catch ({ response }) {
        this.validateFromResponse(response)

        return new Promise((resolve, reject) => {
          reject(response)
        })
      }
    },

    async sendInvitation(employment) {
      try {
        await Employments.invite(employment.id, {
          company_id: this.activeCompany.id,
        })

        this.success('Invitation was successfully sent.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },

    async resetPassword() {
      const confirmed = await this.confirm(
        `Are you sure you want send password reset link to ${this.employment.full_name} ?`,
        'Send password reset link'
      )

      if (!confirmed) return

      this.passwordResetLinkSending = true

      try {
        await Employments.sendPasswordResetLink(this.employment)

        this.success('Password reset link was successfully sent.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.passwordResetLinkSending = false
    },

    async newEmployee() {
      if (this.needsToUpgrade) return await this.confirmUpgrade()

      this.reset()

      this.form.minutes_per_working_day = this.activeCompany.minutes_per_working_day

      this.activeTab = 'profile'

      this.$modal.show('employee-form')
    },

    async editEmployee(employment) {
      this.editing = true

      await this.fetchEmployment(employment.id)

      this.fillEmployeeForm()

      this.resetProrationData()

      this.activeTab = 'profile'

      this.$modal.show('employee-form')
    },

    fillEmployeeForm() {
      this.form = {
        ...this.employment,
        timezone: {
          name: this.employment.timezone,
        },
        start_date: this.employment.start_date
          ? this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
              this.employment.start_date,
              'utc'
            )
          : '',
        end_date: this.employment.end_date
          ? this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
              this.employment.end_date,
              'utc'
            )
          : '',
        date_of_birth: this.employment.date_of_birth
          ? this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
              this.employment.date_of_birth,
              'utc'
            )
          : '',
        minutes_per_working_day: this.minutesPerWorkingDay,
        selectedFollowers: this.employment.followers,
        selectedApprovers: this.employment.approvers,
      }

      this.employmentWorkingSchedule = {
        ...this.employment.available_working_schedule,
      }
    },

    async deleteEmployee(employment) {
      if (employment.user_id === this.activeCompany.owner_id) {
        return this.error("You can't delete owner of the company.")
      }

      const confirmed = await this.confirm(
        'Are you sure you want to delete?',
        'Confirm delete'
      )

      if (!confirmed) return

      try {
        await Employments.delete(employment.id, this.activeCompany.id)

        this.fetchEmployments()
        this.fetchUser()

        this.success('Employee deleted successfully.')
      } catch ({ response }) {
        this.error(response.data.message)
      }
    },

    async restoreEmployment(employment) {
      if (this.needsToUpgrade) return await this.confirmUpgrade()

      const confirmed = await this.confirm(
        'Are you sure you want to restore?',
        'Restore employee'
      )
      if (!confirmed) return

      try {
        await Employments.restore(employment.id, this.activeCompany.id)

        this.fetchEmployments()
        this.fetchUser()

        this.success('Employee restored successfully.')
      } catch ({ response: { data, status } }) {
        if (status === 422) {
          this.validationErrors(data.errors)
        } else {
          this.error('We are having some issues, please try again.')
        }
      }
    },

    async saveEmploymentWorkingSchedule() {
      this.loading = true
      this.editing = true

      if (!this.scheduleValid) return

      try {
        await EmploymentWorkingSchedule.create({
          company_id: this.activeCompany.id,
          employment_id: this.employment.id,
          schedule: this.employmentWorkingSchedule.schedule_breakdowns,
        })

        await this.submitEmployeePayload()

        this.success('Schedule updated successfully.')

        await this.fetchEmployment(this.employment.id)
        await this.refreshSelectedWorkingSchedule()
        this.fetchAuthEmployee()
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },

    async fetchAuthEmployee() {
      if (this.employment && this.isActiveEmployment(this.employment.id)) {
        return this.fetchUser()
      }
    },

    formatYear(date, format = 'YYYY-MM-DD') {
      return moment(date, this.dateFormatWithDashByLocale).format(format)
    },

    reset() {
      this.$nextTick(() => {
        this.loading = false
        this.editing = false
        this.inviting = false

        this.employment = {
          full_name: '',
          minutes_per_working_day: null,
        }

        this.employmentWorkingSchedule = null

        this.resetProrationData()

        this.form = {
          job_title: '',
          full_name: '',
          date_of_birth: '',
          email: '',
          employee_code: '',
          department_id: this.$route.query.department || '',
          timezone: {
            name: this.activeEmployment.timezone,
          },
          is_admin: false,
          start_date: '',
          end_date: '',
          minutes_per_working_day: '',
          allowance_unit_is_days: null,
          visible_scopes: null,
          holiday_location: '',
          selectedFollowers: [],
          selectedApprovers: [],
        }

        this.$validator.reset()
      })
    },

    scheduleValidate(valid) {
      this.scheduleValid = valid
    },

    async confirmUpgrade() {
      const seats = this.numberOfSeats || 5
      const upgradeConfirmed = await this.confirm(
        `Your current plan allows you to have ${seats} users. To add more users you must first upgrade. Would you like to do this?`,
        'Upgrade plan'
      )

      if (!upgradeConfirmed) return
      this.$router.push({ name: 'billing' }, () => {})
    },

    async alignStartDate() {
      if (this.form.align_allowance_to_start_date) {
        const name = this.form.full_name
          ? `${this.form.full_name}'s`
          : 'Employee'

        const confirmed = await this.confirm(
          `${name} leave year will start on ${this.formatDateFromIsoToDayReadableShortDayNumberWithPlaceMonth(
            this.formatYear(this.form.start_date)
          )} each year.`,
          'Are you sure?',
          'modal-header-icon warning-icon-exclamation-circle tw-text-orange-500'
        )

        this.form.align_allowance_to_start_date = !!confirmed

        return
      }

      this.form.align_allowance_to_start_date = false
    },

    resetPagination() {
      this.paginatedEmployments = {
        ...PAGINATED_EMPLOYMENTS,
      }
    },

    setProrationData(dataProrate) {
      if (!dataProrate) {
        return
      }

      this.dataProrate = merge(this.dataProrate, dataProrate)

      this.showProrationModal()
    },

    resetProrationData() {
      this.dataProrate = {
        enabled: false,
        allowanceUpdated: false,
        startDate: null,
        startDateChanged: false,
        startCalendar: null,
        endDate: null,
        endDateChanged: false,
        endCalendar: null,
      }
    },

    setProrationDates() {
      this.dataProrate.startDate = this.momentEmploymentStartDate
      this.dataProrate.endDate = this.momentEmploymentEndDate
    },

    showStartDateProrationModal() {
      this.resetProrationData()

      this.setProrationDates()

      this.dataProrate.startDateChanged = true
      this.dataProrate.startCalendar = this.employmentStartCalendar

      if (this.isInTheSameCalendar) {
        this.dataProrate.endDateChanged = this.isEndDateAvailableForProrate
        this.dataProrate.endCalendar = this.employmentEndCalendar
      }

      this.showProrationModal()
    },

    showEndDateProrationModal() {
      this.resetProrationData()

      this.setProrationDates()

      this.dataProrate.endDateChanged = true
      this.dataProrate.endCalendar = this.employmentEndCalendar

      if (this.isInTheSameCalendar) {
        this.dataProrate.startDateChanged = this.isStartDateAvailableForProrate
        this.dataProrate.startCalendar = this.employmentStartCalendar
      }

      this.showProrationModal()
    },

    showProrationModal() {
      this.dataProrate.enabled = true

      this.$nextTick(() => {
        if (!this.canShowProrationModal) {
          this.resetProrationData()

          return
        }

        this.$modal.hide('employee-form')

        this.$modal.show('employee-allowance-proration')
      })
    },

    closeProrationModal() {
      if (this.dataProrate.allowanceUpdated) {
        this.fetchEmployment(this.employment.id)
      }

      this.$modal.hide('employee-allowance-proration')
    },

    prorationModalClosed() {
      this.$modal.show('employee-form')

      this.resetProrationData()
    },

    getBreakdownsForProration(calendar) {
      const allowances = filter(this.employment.allowances, allowance => {
        return !allowance.is_unlimited && allowance.calendar_id === calendar.id
      })

      return filter(flatMap(allowances, 'breakdowns'), breakdown => {
        return (
          breakdown.yearly_allowance_in_minutes &&
          breakdown.allowance_breakdown_type.policy_settings.type === 1
        )
      })
    },

    hasProratedBreakdowns(calendar) {
      return this.getBreakdownsForProration(calendar).some(
        breakdown => breakdown.is_prorated
      )
    },

    getOverlappingCalendarForDate(calendars, date) {
      if (!date) {
        return null
      }

      return find(calendars, calendar => {
        return date
          .clone()
          .utc(true)
          .isBetween(
            moment.utc(calendar.start_date),
            moment.utc(calendar.end_date),
            'date',
            '[]'
          )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@screen sm {
  .ld-tab-container {
    min-height: 671px;

    .employment-working-schedule {
      min-height: 606px;
    }

    .working-schedule-wrap {
      max-width: 570px;
    }
  }
  .paginate-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
