<template>
  <div
    v-if="!isLoading"
    class="tw-w-full sm:tw-w-8/12 md:tw-w-5/12 lg:tw-w-4/12"
    data-cy="invite-users"
  >
    <form
      class="tw-bg-white tw-shadow-md tw-rounded tw-px-8 tw-pt-6 tw-pb-8 tw-mb-4"
      @submit.prevent="submit"
    >
      <p class="tw-mt-4 tw-mb-2 tw-text-md tw-text-gray-600">
        Leave Dates works best when your team are here too.
      </p>
      <p class="tw-mb-8 tw-text-2xl tw-text-gray-800">
        Invite people to
        <span class="tw-font-semibold">{{ activeCompany.name }}</span>
      </p>
      <div class="tw--mx-3">
        <div class="tw-hidden sm:tw-flex tw-w-full">
          <div class="sm:tw-w-1/2 tw-px-3">
            <label class="form-label">Full Name</label>
          </div>
          <div class="sm:tw-w-1/2 tw-px-3">
            <label class="form-label">Email Address</label>
          </div>
          <div style="width: 26px;"></div>
        </div>

        <div
          v-for="(user, index) in users"
          :key="user.key"
          class="tw-mb-6 sm:tw-mb-4 tw-flex tw-items-center tw-justify-between"
          data-cy="invitee-row"
        >
          <div class="tw-w-full sm:tw-flex">
            <div class="tw-w-full sm:tw-w-1/2 tw-px-3">
              <label class="form-label sm:tw-hidden">Full Name</label>
              <div class="tw-flex tw-justify-center tw-items-center">
                <input
                  v-model="user.name"
                  v-validate.disable="'required'"
                  :name="`users.${user.key}.name`"
                  :placeholder="`Example ${index + 1}`"
                  type="text"
                  autocomplete="off"
                  class="form-control"
                  data-vv-as="name"
                  data-cy="invitee-full-name"
                />
                <div class="sm:tw-hidden" style="width: 32px;"></div>
              </div>
              <p
                v-show="errors.has(`users.${user.key}.name`)"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first(`users.${user.key}.name`) }}
              </p>
            </div>
            <div class="tw-w-full sm:tw-w-1/2 tw-px-3 sm:tw-pl-0 section-right">
              <label class="form-label sm:tw-hidden tw-mt-2 sm:tw-mt-0"
                >Email Address</label
              >
              <div class="tw-flex tw-justify-center tw-items-center">
                <input
                  v-model="user.email"
                  v-validate.disable="'required|email'"
                  :name="`users.${user.key}.email`"
                  :placeholder="`example${index + 1}@example.com`"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  data-vv-as="email"
                  data-cy="invitee-email"
                />
                <div
                  class="tw-flex tw-items-center tw-justify-end"
                  style="width: 32px;"
                >
                  <button
                    v-if="index > 0"
                    class="btn btn-icon tw-w-6 tw-h-6 tw-border tw-border-red-500 tw-rounded-full hover:tw-bg-red-500 btn-remove-field"
                    title="Remove"
                    type="button"
                    data-cy="remove-user"
                    style="padding: 3px; width: 18px; height: 18px;"
                    @click="removeUser(user.key)"
                  >
                    <div
                      class="tw-flex tw-items-center tw-justify-center"
                      style="width: 10px; height: 8px;"
                    >
                      <svg-icon
                        name="close"
                        class="svg-icon tw-cursor-pointer tw-text-red-500"
                      />
                    </div>
                  </button>
                </div>
              </div>
              <p
                v-show="errors.has(`users.${user.key}.email`)"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first(`users.${user.key}.email`) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="users.length < availableSeats" class="tw-flex tw-items-center">
        <div
          class="tw-cursor-pointer tw-flex tw-items-center"
          data-cy="add-another"
          @click="addRow"
        >
          <svg-icon
            name="add-outline"
            class="tw-mr-1 tw-w-4 tw-h-4 tw-text-blue-500"
          />
          <span class="tw-text-blue-500 hover:tw-underline">Add another</span>
        </div>
      </div>
      <div class="tw-flex tw-justify-end tw-items-center tw-mt-5">
        <button
          class="btn btn-borderless btn-link btn-link_focus tw-text-gray-600"
          type="button"
          data-cy="btn-skip-invite-people"
          @click="skip"
        >
          Skip
        </button>
        <button class="btn btn-blue" type="submit" data-cy="btn-invite">
          Invite
        </button>
      </div>
    </form>

    <auth-footer />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AuthFooter from '@/components/AuthFooter'
import ValidatesForm from '@/mixins/ValidatesForm'
import Subscription from '@/mixins/Subscription'
import { times, uniqueId } from 'lodash-es'
import ExampleEmployment from '@/components/employee/ExampleEmployment'
import InvitedEmployments from '@/components/employee/InvitedEmployments'

export default {
  name: 'InviteUsers',

  components: { AuthFooter },

  mixins: [ValidatesForm, Subscription],

  data: () => ({
    users: [],
    isLoading: true,
  }),

  computed: {
    availableSeats() {
      return this.numberOfSeats - this.activeEmploymentsCount
    },
  },

  watch: {
    '$route.query.company': {
      async handler(companyKey) {
        await this.fetchCompany({ id: companyKey })

        this.isLoading = false
      },
      immediate: true,
    },
  },

  created() {
    times(3, () => this.addRow())
  },

  methods: {
    ...mapActions('auth', ['fetchUser', 'fetchCompany']),

    removeUser(key) {
      this.users = this.users.filter(user => user.key !== key)
    },

    async submit() {
      await this.validate()

      if (!this.valid) return

      try {
        await InvitedEmployments.create({
          companyKey: this.activeCompany.id,
          timezone: this.activeEmployment.timezone,
          approvers: [this.activeEmployment.id],
          users: this.users,
        })

        this.success('Users successfully invited.')

        this.next()
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },

    async skip() {
      try {
        await ExampleEmployment.create(2, {
          company_id: this.activeCompany.id,
          timezone: this.activeEmployment.timezone,
          approvers: [this.activeEmployment.id],
        })

        this.next()
      } catch ({ response }) {
        if (response.status === 402) {
          this.next()
          return
        }

        this.validateFromResponse(response)
      }
    },

    async next() {
      await this.fetchUser()

      this.$router.push(
        { name: 'wall-chart', query: { ...this.$route.query, tour: 'yes' } },
        () => {}
      )
    },

    addRow() {
      this.users.push({ key: uniqueId(), name: '', email: '' })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-remove-field:hover div svg {
  @apply .text-white;
}

@screen sm {
  .section-right {
    margin-left: -2px;
  }
}
</style>
