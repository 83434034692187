import { Employments } from '@/api'
import Promise from 'lodash-es/_Promise'
import { reject } from 'lodash-es'

export default class InvitedEmployments {
  static errors = []
  static alreadyInvitedEmployments = []

  static async create(payload = {}) {
    const { users } = payload

    for (const employment of this._getUnInvitedEmployments(users)) {
      try {
        await this._inviteEmployment(employment, payload)
      } catch (e) {
        this.errors.push({ employment, error: e })
      }
    }

    if (this.errors.length) {
      for (const { error } of this.errors) {
        if (this._hasCompanySubscriptionExceeded(error)) {
          return Promise.reject(error)
        }
      }

      return this._handleValidationErrors(this.errors)
    }
  }

  static _getUnInvitedEmployments(users) {
    return reject(users, user =>
      this.alreadyInvitedEmployments.some(
        ({ name, email }) => name === user.name && email === user.email
      )
    )
  }

  static async _inviteEmployment(employment, payload) {
    const { companyKey, timezone, approvers } = payload

    const {
      data: { id: employmentKey },
    } = await Employments.create({
      company_id: companyKey,
      timezone,
      approvers,
      full_name: employment.name.trim(),
      email: employment.email,
    })

    await Employments.invite(employmentKey, {
      company_id: companyKey,
    })
    this._addEmploymentToInvitedList(employment)
  }

  static _addEmploymentToInvitedList(employment) {
    this.alreadyInvitedEmployments.push({
      name: employment.name,
      email: employment.email,
    })
  }

  static _hasCompanySubscriptionExceeded(error) {
    return error.response.status === 402
  }

  static _handleValidationErrors(errors) {
    let validationErrors = {}

    for (const { employment, error } of errors) {
      validationErrors = {
        ...validationErrors,
        ...this._generateValidationErrorsPerEmployment(employment, error),
      }
    }

    const response = this._getResponseWithValidationErrors(
      errors[0].error,
      validationErrors
    )

    this.errors = []
    return Promise.reject(response)
  }

  static _generateValidationErrorsPerEmployment(employment, error) {
    let errors = {}
    for (let [key, value] of this._getResponseErrors(error)) {
      if (key === 'full_name') {
        key = 'name'
      }

      if (!Object.keys(errors).includes(key)) {
        errors[`users.${employment.key}.${key}`] = value
      }
    }

    return errors
  }

  static _getResponseErrors(error) {
    return Object.entries(error.response.data.errors)
  }

  static _getResponseWithValidationErrors(response, validationErrors) {
    response.response.data.errors = validationErrors
    return response
  }
}
